.row-footer-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 1rem;
}
.footer-left-text{
    text-align: start;
    padding-top: 1rem;
    font-size: 1.2rem;
}
.footer-left-text-bold{
    text-align: start;
    padding-top: 1rem;
    font-size: 1.2rem;
    font-weight: bold;
}