.wrapperTopNav{
    color: black;
    margin-top: 5rem;
    flex: 1;
}
.navbarWrapper { 
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 1rem 0rem;
}
.singleNavBarElement {
    font-size: 1.2rem;
    padding: 0.3rem;
    margin: 0 0.3rem;
    cursor: pointer;
    transition: width 1s linear 2s;
    color: #06328a;
}
.singleNavBarElementIconAndLabel{
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #06328a;
}
.singleNavBarElement:hover {
    color: #06328a;
    font-weight: bolder;
}
.singleNavBarElement::selection {
    color: #06328a;
}
.active-tab {
    cursor: pointer;
    font-weight: bolder;
}