.header-wrapper{
    display: flex;
    flex-direction: row;
    position: fixed;
    top: 0;
    background-color: white;
    width: 100%;
    z-index: 4;
}
.logo-text-wrapper-div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 0.5rem;
}
.logo-text{
    font-size: 1.5rem;
    font-style: italic;
}
@media only screen and (max-width : 1224px) {
    .logo-image {
        height: 5rem;
        width: 100vw;
    }
    .header-wrapper {
        flex-direction: column;
    }
    .logo-text{
        font-size: 1.3rem;
        font-style: italic;
    }
}