.body-wrapper {
    background-color: #104862;
    padding: 0rem 0;
    margin-top: 10rem;
}
.body-text-bolder{
    font-size: 3rem;
    font-weight: bolder;
    color: white;
}

.empty-white-space{
    height: 200px;
    background-color: white;
}

.body-text-bold{
    font-size: 1.5rem;
    font-weight: bold;
    color: white;
}
.added-text-color{
    color : #C04F15
}
.text-wrapper {
    font-size: 2rem;
}
.normal-flex-with-row {
    display :flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.second-body-text{
    text-align: start;
}
.background-color{
    padding-left: 1rem;
    font-size: 2rem;
    font-weight: bolder;
    color: white;
    width: fit-content;
    padding: 0.5rem 1rem;
}
.blue{
    background-color: #104862;
}
.orange{
    background-color: #C04F15;
}
.yellow-text-color {
    color: yellow
}
.title {
    font-size: 6rem;
}

.image-wrapper {
    padding: 0 4rem;
    text-align: start;
}
.added-text { 
    font-size: 1rem;
    padding: 4rem 4rem;
    line-height: 2rem;
}

.subtext{
    font-size: 1rem;
}
.bold-text-with-vertical-padding{
    font-weight: bold;
}

.opening-wrapper{
    display: flex;
}

.opening-single-element{
    border : 2px solid rgb(228, 228, 228);
    margin-right: 0.5rem;
    padding: 0.5rem;
}

.opening-div{
    color: white;
    font-weight: bold;
    background-color: #2293d7;
    text-align: center;
}

.carousel-item {
    opacity: 1;
    transition-duration: .6s;
    transition-property: opacity;
  }
.transition-class-with-fade{
    animation: fadeIn 0.5s ease-in; 
}

@keyframes fadeIn {  
    from {  
        opacity:0;  
    }  
 
    to {  
        opacity:1;  
    }  
 }

.image-div-wrapper{ 
    display: flex;
    flex-direction: row;
    justify-content: space-evenly 
}
.slide selected {
    height: 50vh;
}

.transition-class-with-fade{
    margin: auto 0;
    height: 100%;
    width: 100vw;
}
.picture-on-right-banner{
    margin-top: 4rem;
}


@media only screen and (max-width: 1224px) {
    .added-text{
        padding: 1rem 1rem
    }
    .normal-flex-with-row {
        flex-direction: column;
    }
    .second-body-text{
        display: flex;
        flex-direction: column;
    }
    .text-wrapper {
        margin: 0;
        display : flex;
        width : 100vw
    }
    .background-color {
        text-align: center;
        margin: 0;
        display : flex;
        flex-direction: column;
        width : 100vw
    }
    .opening-wrapper{
        flex-direction: column;
    }
    .image-div-wrapper{ 
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly 
    }
    .picture-on-right-banner{
        flex-direction: column;
        margin-top: 0;
    }
    .list-of-images{
        flex-direction: column;
    }
}